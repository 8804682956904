<template>
  <v-container>
    <v-layout row wrap>
      <!--<v-flex xs12>
        Amount in account: {{account.accountCurrency}} {{account.accountAmount}}
      </v-flex>
      <v-flex xs12>
        Account Type: {{account.accountType}}
      </v-flex>
      <v-flex xs12>
        Account Description: {{account.accountDescription}}
      </v-flex>-->
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    props: ['incomeId'],
    data() {
      return {
        income: []
      }
    },
    beforeMount() {
      let inc = this.$store.state.Incomes
      for (let i = 0; i < inc.length; i++) {
        if (inc[i].id === this.incomeId) {
          this.income = inc[i]
          break
        }
      }
    }
  }
</script>
